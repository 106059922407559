<style lang="less" scoped>
  @import './login.less';
</style>

<template>
  <div class="login-page">
    <div class="login">
      <div class="login-con">
        <Card icon="log-in" title="欢迎登录" :bordered="false">
          <div class="form-con">
            <login-form @on-success-valid="handleSubmit"></login-form>
            <!-- <p class="login-tip">输入任意用户名和密码即可</p> -->
          </div>
        </Card>
      </div>
    </div>
  </div>
</template>

<script>
import LoginForm from './components/login-form';
import { login, getUserInfo } from '@/api/login';
import { useRouter } from 'vue-router';
import { Message } from "view-ui-plus";

const modules_enum = {
  1: 'productBackend',
  2: 'orderBackend'
}
export default {
  components: {
    LoginForm
  },
  setup() {
    const router = useRouter();
    function handleSubmit ({ username, password }) {
      console.log(username)
      console.log(password)
      // initData()
      // history.pushState(null, "productBackend", "/productBackend")
      // return
      login({ username, password }).then(res => {
        console.log(res)
        if (res.data.code === 200) {
          const { token } = res.data;
          localStorage.setItem('TOKEN', token)
          getInfo(token)
        } else {
          Message.warning(res.data.msg)
        }
      })
    }

    function getInfo(token) {
      getUserInfo(token).then(res => {
        console.log(res)
        if (res.data.code === 200) {
          const userInfoData = res.data;
          // 模块权限
          localStorage.setItem('MODULES_PERMISSIONS', userInfoData.modules);
          // 订单模块
          localStorage.setItem('loginForm', userInfoData.user.userName);
          // 会员模块
          localStorage.setItem('userName', userInfoData.user.userName);
          // 商品模块
          localStorage.setItem('USERINFOSTORAGE', JSON.stringify(userInfoData))
          localStorage.setItem('CHANNELNAME', userInfoData.user.channelName)
          localStorage.setItem('CHANNELID', userInfoData.user.channelId)
          localStorage.setItem('BUSINESSCODE', userInfoData.user.businessCode)

          // 默认进入一个模块（根据用户权限判断）
          const defaultMoules = userInfoData.modules[0];
          const moduleName = modules_enum[defaultMoules];
          const routerName = `/${moduleName}`;

          console.log(defaultMoules)
          console.log(moduleName)
          console.log(routerName)

          // 登录成功后进入首页
          router.push('/home')
          history.pushState(null, moduleName, routerName)
        } else {
          Message.warning(res.data.msg)
        }
      })
    }

    return {
      handleSubmit
    }
  }
}
</script>

<style>

</style>
